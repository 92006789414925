<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Suspend subscription"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Suspend subscription</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
      <h2 class="font-bold my-4">
        Are you sure you want to suspend your Mastermind subscription?
      </h2>
    </template>
    <template v-slot:body>
      <div>
        <h1>
          The user and the associated profiles will have access to the VR
          platform until
          <span class="font-bold">{{ subscriptionExpiryDate }}</span
          >.
        </h1>
        <h1>
          All the users information will remain available on the web platform.
        </h1>
      </div>
      <div class="mt-4">
        <input
          type="checkbox"
          v-model="isConfirmChecked"
          @change="toggleConfirmButton"
        />
        <span class="ml-2">
          I understand, and I want to suspend the subscription.
        </span>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  subscriptionExpiryDate: String,
  isLoading: Boolean,
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const isConfirmChecked = ref(false);

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  isConfirmChecked.value = false;
  isConfirmDisabled.value = true;
  emit("update:show", false);
}

function toggleConfirmButton() {
  isConfirmDisabled.value = !isConfirmChecked.value;
}

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
