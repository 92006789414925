<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Restore subscription"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Activate subscription</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div>
        <h1 class="mt-4">
          Select all the players you want to activate, the new amount to pay
          will depend on these players, the players that you do not select will
          remain deactivated.
        </h1>
      </div>
      <div class="overflow-y-auto max-h-96 mt-4 border border-gray-300">
        <table class="min-w-full bg-white">
          <thead>
            <tr class="w-full bg-gray-100">
              <th class="px-4 py-4 border-b text-left">User name</th>
              <th class="px-4 py-4 border-b text-left">Date of birth</th>
              <th class="px-4 py-4 border-b text-left">Phone number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(player, index) in players" :key="index">
              <td class="px-4 py-4 border-b">
                <input
                  type="checkbox"
                  :value="player.id"
                  v-model="selectedPlayers"
                  class="mr-2"
                />
                {{ player.username }}
              </td>
              <td class="px-4 py-4 border-b">{{ player.date_birth }}</td>
              <td
                :class="[player.phone_number ? '' : 'text-center']"
                class="px-4 py-4 border-b"
              >
                {{ player.phone_number ? player.phone_number : "-" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  subscriptionExpiryDate: String,
  isLoading: Boolean,
  players: Array,
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const selectedPlayers = ref([]);

function handleConfirm() {
  emit("confirm", selectedPlayers.value);
}

function handleCancel() {
  isConfirmDisabled.value = true;
  selectedPlayers.value = [];
  emit("update:show", false);
}

watch(selectedPlayers, (selectedPlayer) => {
  isConfirmDisabled.value = selectedPlayer.length === 0;
});

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
