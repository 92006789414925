<template>
  <Modal
    :isConfirmDisabled="false || isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Confirm"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
    :isLoading="isLoading"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Activate subscription</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
      <h2 class="my-4">
        Please verify that all the information is correct before proceeding to
        payment. Once you confirm, the payment will be made using the selected
        payment method.
      </h2>
    </template>
    <template v-slot:body>
      <div v-if="isLoadingData" class="flex justify-center items-center py-4">
        <em class="fa fa-spinner fa-spin"></em>
      </div>
      <div v-else>
      <h2 class="font-bold mb-2">New plan</h2>
      <h2 class="my-2">
        The new ammount to be paid on the next invoice will be
        <span class="font-bold">${{ paymentData.nextInvoiceTotal }}</span>
      </h2>
      <h2 class="font-bold mt-2 mb-2">Card details</h2>
      <h2 class="my-2">
        {{ paymentData.cardBrand.toUpperCase() }} ****{{
          paymentData.cardLast4
        }}
      </h2>
      <h2 class="font-bold mt-2 mb-2">Next payment</h2>
      <h2 class="my-2">
        The next payment date will be from
        <span class="font-bold">{{ paymentData.nextPaymentDate }}</span>
      </h2>
    </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";
import { subscriptionNextInvoiceTotal } from "@/services/stripe/stripe.js";

const props = defineProps({
  show: Boolean,
  subscriptionExpiryDate: String,
  newSubscriptionId: String,
  isLoading: Boolean
});

const emit = defineEmits(["update:show", "confirm"]);

const isLoadingData = ref(true)
const paymentData = ref({
  nextInvoiceTotal: 0,
  cardLast4: "",
  cardBrand: "",
  nextPaymentDate: "",
});
const isConfirmDisabled = ref(false);

async function fetchNextInvoiceTotal() {
  const nextInvoiceTotal = await subscriptionNextInvoiceTotal(props.newSubscriptionId);
  paymentData.value = {
    nextInvoiceTotal: nextInvoiceTotal.next_invoice_total,
    cardLast4: nextInvoiceTotal.card_last4,
    cardBrand: nextInvoiceTotal.card_display_brand,
    nextPaymentDate: nextInvoiceTotal.next_payment_date,
  };
  isLoadingData.value = false
}

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  paymentData.value = {
    nextInvoiceTotal: 0,
    cardLast4: "",
    cardBrand: "",
    nextPaymentDate: "",
  }
  isLoadingData.value = true
  emit("update:show", false);
}

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      fetchNextInvoiceTotal();
    }
  }
);

watch(
  () => props.isLoading,
  (newValue) => {
    isConfirmDisabled.value = newValue;
  }
);
</script>
