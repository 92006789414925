<template>
  <Modal
    :isConfirmDisabled="isConfirmDisabled"
    :show="show"
    @confirm="handleConfirm"
    confirmationText="Restore subscription"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="true"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">Activate subscription</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
    </template>
    <template v-slot:body>
      <div>
        <h1 class="mt-4">
          The user and the associated profiles will have access to the VR
          platform until
          <span class="font-bold">{{ subscriptionExpiryDate }}</span
          >. All the users information will remain available on the web
          platform.
        </h1>
      </div>
      <div class="flex justify-center mt-4">
        <div
          :class="[
            'option-box',
            selectedOption === 'current'
              ? 'border-blue-500'
              : 'border-gray-300',
          ]"
          @click="selectOption('current')"
          class="border p-4 w-1/2 cursor-pointer transition-colors p-12"
        >
          <h3 class="text-center font-semibold">{{ providerText.current }}</h3>
          <p class="text-center">{{ providerText.currentDescription }}</p>
        </div>
        <div
          :class="[
            'option-box',
            selectedOption === 'custom' ? 'border-blue-500' : 'border-gray-300',
            selectedOption === 'custom' ? 'border-l border-blue-500' : '',
          ]"
          @click="selectOption('custom')"
          class="border p-4 w-1/2 cursor-pointer transition-colors border-l-0 p-12"
        >
          <h3 class="text-center font-semibold">Custom</h3>
          <p class="text-center">
            Select the profiles that you want to activate
          </p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed } from "vue";
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  subscriptionExpiryDate: String,
  provider: {
    type: String,
    default: "stripe",
  },
});

const emit = defineEmits(["update:show", "confirm"]);

const isConfirmDisabled = ref(true);
const selectedOption = ref("");

const providerText = computed(() => {
  if (props.provider.toLowerCase() === "stripe") {
    return {
      current: "Current",
      currentDescription: "Restore all user profiles",
    };
  } else {
    return {
      current: "Add new players",
      currentDescription: "",
    };
  }
});

function handleConfirm() {
  emit("confirm", selectedOption.value);
}

function handleCancel() {
  selectedOption.value = "";
  isConfirmDisabled.value = true;
  emit("update:show", false);
}

function selectOption(option) {
  selectedOption.value = option;
  isConfirmDisabled.value = false;
}
</script>

<style scoped>
.option-box {
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.option-box:hover {
  background-color: #f0f4f8;
}
</style>
